<template>
    <KCourse loader-class="MBcont" course-id="3" title="Роспись и парик" :items="items">
        <div class="MBtextcont">
            <h3>2.1 Красим волосы</h3>
        </div>

        <VideoView video-id="e101683d53fd46b9970b2da1f35d0ffe"/>

        <div class="MBtextcont">
            <h3>2.2 Делаем шапочку для волос </h3>
            <p>Только для шарнирок</p>
        </div>

        <VideoView video-id="622d949177614455885306120374f2d1"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Образ", url:"/mycourses/draw/1"},
                { title: "Подготовка", url:"/mycourses/draw/2"},
                { title: "Грунтуем и красим", url:"/mycourses/draw/3"},
                { title: "Расписываем", url:"/mycourses/draw/4"},
                { title: "Делаем парик", url:"/mycourses/draw/5"},
            ],
        }
        }
    }
</script>